// router.js
import Vue from 'vue'
import VueRouter from 'vue-router'
// 越南namvaytien
// import MyFirst from './namvaytien/MyFirst.vue'
// import MyAbout from './namvaytien/MyAbout.vue'
// import MyProtocol from './namvaytien/MyProtocol.vue'
// import MyQuestion from './namvaytien/MyQuestions.vue'

//泰国chatchaimobile
// import MyFirst from './chatchaimobile/MyFirst.vue'
// import MyProtocol from './chatchaimobile/MyProtocol.vue'


//泰国jaidee
// import MyFirst from './jaidee/MyFirst.vue'
// import MyProtocol from './jaidee/MyProtocol.vue'
// import MyService from './jaidee/MyService.vue'
// import MyQuestion from './jaidee/MyQuestions.vue'
//泰国第二个jaidee
// import MyFirst from './weimingm/MyFirst.vue'
// import MyProtocol from './weimingm/MyProtocol.vue'
// import MyAbout from './weimingm/MyAbout.vue'
// import MyClause from './weimingm/MyClause.vue'

//越南kasikvay
// import MyFirst from './kasikvay/MyFirst.vue'

// import MyAbout from './kasikvay/MyAbout.vue'
// import MyClause from './kasikvay/MyClause.vue'
// import MyQuestions from './kasikvay/MyQuestions.vue'
// import MyProtocol from './kasikvay/MyProtocol.vue'


//越南tamogiefectivo
// import MyFirst from './tamogiefectivo/MyFirst.vue'
// import MyProtocol from './tamogiefectivo/MyProtocol.vue'
// import MyAbout from './tamogiefectivo/MyAbout.vue'
// import MyQuestion from './tamogiefectivo/MyQuestions.vue'
// import MyCenter from './tamogiefectivo/MyCenter.vue'

//墨西哥consedeefectivo
// import MyFirst from './consedeefectivo/MyFirst.vue'
// import MyAbout from './consedeefectivo/MyAbout.vue'
// import MyService from './consedeefectivo/MyService.vue'
// import MyProtocol from './consedeefectivo/MyProtocol.vue'

//墨西哥betacreditdinero
// import MyFirst from './betacreditdinero/MyFirst.vue'
// import MyAbout from './betacreditdinero/MyAbout.vue'
// import MyContact from './betacreditdinero/MyContact.vue'
// import MyProtocol from './betacreditdinero/MyProtocol.vue'

//墨西哥cherrypre
// import MyFirst from './cherreypréstamos/MyFirst.vue'
// import MyAbout from './cherreypréstamos/MyAbout.vue'
// import MyService from './cherreypréstamos/MyService.vue'
// import MyQuestions from './cherreypréstamos/MyQuestions.vue'
// import MyProtocol from './cherreypréstamos/MyProtocol.vue'

//越南nathcopay
// import MyFirst from './nathcopay/MyFirst.vue'
// import MyQuestions from './nathcopay/MyQuestions.vue'
// import MyProtocol from './nathcopay/MyProtocol.vue'

//越南mrVaymobile
// import MyFirst from './mrVaymobile/MyFirst.vue'
// import MyProtocol from './mrVaymobile/MyProtocol.vue'
// import MyAbout from './mrVaymobile/MyAbout.vue'
// import MyContact from './mrVaymobile/MyContact.vue'

//越南hcthlogplus
// import MyFirst from './hcthlogplus/MyFirst.vue'
// import MyProtocol from './hcthlogplus/MyProtocol.vue'
// import MyAbout from './hcthlogplus/MyAbout.vue'
// import MyProcess from './hcthlogplus/MyProcess.vue'


//越南hipotecariabilletera
// import MyFirst from './hipotecariabilletera/MyFirst.vue'
// import MyProtocol from './hipotecariabilletera/MyProtocol.vue'
// import MyPaso from './hipotecariabilletera/MyPaso.vue'
// import MyProcess from './hipotecariabilletera/MyQuestions.vue'
// import MyContact from './hipotecariabilletera/MyContact.vue'
// import MyContrato from './hipotecariabilletera/MyContrato.vue'

// 越南sunmobile
// import MyFirst from './sunmobile/MyFirst.vue'
// import MyProtocol from './sunmobile/MyProtocol.vue'
// import MyPaso from './sunmobile/MyPaso.vue'
// import MyQuestions from './sunmobile/MyQuestions.vue'
// import MyContact from './sunmobile/MyContact.vue'

// 越南cathaydonge
// import MyFirst from './cathaydong/MyFirst.vue'
// import MyProtocol from './cathaydong/MyProtocol.vue'
// import MyQuestions from './cathaydong/MyQuestions.vue'
// import MyService from './cathaydong/MyService.vue'


// 越南newvay
// import MyFirst from './newvay/MyFirst.vue'
// import MyProtocol from './newvay/MyProtocol.vue'
// import MyQuestions from './newvay/MyQuestions.vue'
// import MyAbout from './newvay/MyAbout.vue'
// import MyService from './newvay/MyService.vue'

//墨西哥eldefiardinero
// import MyFirst from './eldefiardinero/MyFirst.vue'
// import MyProtocol from './eldefiardinero/MyProtocol.vue'
// import MyQuestions from './eldefiardinero/MyQuestions.vue'
// import MyAbout from './eldefiardinero/MyAbout.vue'
// import MyPaso from './eldefiardinero/MyNews.vue'


//墨西哥thanhchungplus
// import MyFirst from './thanhchungplus/MyFirst.vue'
// import MyProtocol from './thanhchungplus/MyProtocol.vue'
// import MyQuestions from './thanhchungplus/MyQuestions.vue'
// import MyAbout from './thanhchungplus/MyAbout.vue'
// import MyPaso from './thanhchungplus/MyNews.vue'


//墨西哥dealcorpefectivo
// import MyFirst from './dealcorpefectivo/MyFirst.vue'
// import MyProtocol from './dealcorpefectivo/MyProtocol.vue'
// import MyService from './dealcorpefectivo/MyService.vue'
// import MyContact from './dealcorpefectivo/MyContact.vue'


//墨西哥kampalacash
// import MyFirst from './kampalacash/MyFirst.vue'
// import MyProtocol from './kampalacash/MyProtocol.vue'
// import MyService from './kampalacash/MyService.vue'
// import MyAbout from './kampalacash/MyAbout.vue'



// 泰国pheera
// import MyFirst from './pheera/MyFirst.vue'
// import MyProtocol from './pheera/MyProtocol.vue'
// import MyPaso from './pheera/MyService.vue'
// import MyQuestions from './pheera/MyQuestions.vue'
// import MyContact from './pheera/MyContact.vue'
// import MyAbout from './pheera/MyAbout.vue'


// 越南piwebplus
// import MyFirst from './piwebplus/MyFirst.vue'
// import MyProtocol from './piwebplus/MyProtocol.vue'
// import MyPaso from './piwebplus/MyService.vue'
// import MyQuestions from './piwebplus/MyQuestions.vue'
// import MyContact from './piwebplus/MyAbout.vue'

// 越南piwebplus2
// import MyFirst from './piwebplus2/MyFirst.vue'
// import MyProtocol from './piwebplus2/MyProtocol.vue'
// import MyPaso from './piwebplus2/MyService.vue'
// import MyQuestions from './piwebplus2/MyQuestions.vue'
// import MyContact from './piwebplus2/MyAbout.vue'

// 墨西哥directodoefectivo
// import MyFirst from './directodoefectivo/MyFirst.vue'
// import MyProtocol from './directodoefectivo/MyProtocol.vue'
// import MyPaso from './directodoefectivo/MyService.vue'
// import MyQuestions from './directodoefectivo/MyQuestions.vue'
// import MyContact from './directodoefectivo/MyAbout.vue'

// 泰国Jaideeonline
// import MyFirst from './Jaideeonline/MyFirst.vue'
// import MyProtocol from './Jaideeonline/MyProtocol.vue'
// import MyPaso from './Jaideeonline/MyService.vue'
// import MyQuestions from './Jaideeonline/MyQuestions.vue'
// import MyContact from './Jaideeonline/MyAbout.vue'

// 墨西哥braskemefectivo
// import MyFirst from './braskemefectivo/MyFirst.vue'
// import MyProtocol from './braskemefectivo/MyProtocol.vue'
// import MyPaso from './braskemefectivo/MyService.vue'
// import MyQuestions from './braskemefectivo/MyQuestions.vue'
// import MyContact from './braskemefectivo/MyAbout.vue'

// 墨西哥dealcorpefectivo2
// import MyFirst from './dealcorpefectivo2/MyFirst.vue'
// import MyProtocol from './dealcorpefectivo2/MyProtocol.vue'
// import MyPaso from './dealcorpefectivo2/MyService.vue'
// import MyQuestions from './dealcorpefectivo2/MyQuestions.vue'
// import MyContact from './dealcorpefectivo2/MyAbout.vue'

// 墨西哥ceibamóvil
// import MyFirst from './ceibamóvil/MyFirst.vue'
// import MyProtocol from './ceibamóvil/MyProtocol.vue'
// import MyPaso from './ceibamóvil/MyService.vue'
// import MyQuestions from './ceibamóvil/MyQuestions.vue'
// import MyContact from './ceibamóvil/MyAbout.vue'
// import MyCommunity from './ceibamóvil/MyCommunity.vue'


// 墨西哥estimulocrédito
// import MyFirst from './estimulocrédito/MyFirst.vue'
// import MyProtocol from './estimulocrédito/MyProtocol.vue'
// import MyPaso from './estimulocrédito/MyService.vue'
// import MyQuestions from './estimulocrédito/MyQuestions.vue'
// import MyContact from './estimulocrédito/MyAbout.vue'
// import MyHelp from './estimulocrédito/MyHelp.vue'
// import MyProcess from './estimulocrédito/MyProcess.vue'
// import MyCondition from './estimulocrédito/MyCondition.vue'

// 墨西哥cincodospréstamos
// import MyFirst from './cincodospréstamos/MyFirst.vue'
// import MyProtocol from './cincodospréstamos/MyProtocol.vue'
// import MyPaso from './cincodospréstamos/MyService.vue'
// import MyQuestions from './cincodospréstamos/MyQuestions.vue'
// import MyContact from './cincodospréstamos/MyAbout.vue'
// import MyCommunity from './cincodospréstamos/MyCommunity.vue'
// import MyTips from './cincodospréstamos/MyTips.vue'

// 墨西哥concreecefectivo
// import MyFirst from './concreecefectivo/MyFirst.vue'
// import MyProtocol from './concreecefectivo/MyProtocol.vue'
// import MyPaso from './concreecefectivo/MyService.vue'
// import MyQuestions from './concreecefectivo/MyQuestions.vue'
// import MyContact from './concreecefectivo/MyAbout.vue'
// import MyCommunity from './concreecefectivo/MyCommunity.vue'


// // 墨西哥daraptipago和wiicredi
// import MyFirst from './daraptipago/MyFirst.vue'
// import MyProtocol from './daraptipago/MyProtocol.vue'
// import MyPaso from './daraptipago/MyService.vue'
// import MyQuestions from './daraptipago/MyQuestions.vue'
// import MyContact from './daraptipago/MyAbout.vue'

// 泰国microfinmobile
// import MyFirst from './microfinmobile/MyFirst.vue'
// import MyProtocol from './microfinmobile/MyProtocol.vue'
// import MyPaso from './microfinmobile/MyService.vue'
// import MyQuestions from './microfinmobile/MyQuestions.vue'
// import MyContact from './microfinmobile/MyAbout.vue'
// import MyCommunity from './microfinmobile/MyCommunity.vue'




// 墨西哥ammóvil
// import MyFirst from './ammóvil/MyFirst.vue'
// import MyProtocol from './ammóvil/MyProtocol.vue'
// import MyPaso from './ammóvil/MyService.vue'
// import MyQuestions from './ammóvil/MyQuestions.vue'
// import MyContact from './ammóvil/MyAbout.vue'

// 墨西哥ngernsaen
// import MyFirst from './ngernsaen/MyFirst.vue'
// import MyProtocol from './ngernsaen/MyProtocol.vue'
// import MyPaso from './ngernsaen/MyService.vue'
// import MyQuestions from './ngernsaen/MyQuestions.vue'
// import MyContact from './ngernsaen/MyAbout.vue'


// 泰国domcap
// import MyFirst from './domcap/MyFirst.vue'
// import MyProtocol from './domcap/MyProtocol.vue'
// import MyPaso from './domcap/MyService.vue'
// import MyQuestions from './domcap/MyQuestions.vue'
// import MyContact from './domcap/MyAbout.vue'


//墨西哥cherreypréstamos2
// import MyFirst from './cherreypréstamos2/MyFirst.vue'
// import MyAbout from './cherreypréstamos2/MyAbout.vue'
// import MyService from './cherreypréstamos2/MyService.vue'
// import MyQuestions from './cherreypréstamos2/MyQuestions.vue'
// import MyProtocol from './cherreypréstamos2/MyProtocol.vue'
// import MyHelp from './cherreypréstamos2/MyHelp.vue'
// import MyProtocol2 from './cherreypréstamos2/MyProtocol2.vue'

// 墨西哥facicasapresta
// import MyFirst from './facicasapresta/MyFirst.vue'
// import MyProtocol from './facicasapresta/MyProtocol.vue'
// import MyService from './facicasapresta/MyService.vue'
// import MyQuestions from './facicasapresta/MyQuestions.vue'
// import MyAbout from './facicasapresta/MyAbout.vue'



// 墨西哥eliteloan
// import MyFirst from './eliteloan/MyFirst.vue'
// import MyProtocol from './eliteloan/MyProtocol.vue'
// import MyPaso from './eliteloan/MyService.vue'
// import MyQuestions from './eliteloan/MyQuestions.vue'
// import MyContact from './eliteloan/MyAbout.vue'

// 越南drkinhtiềnbạc
// import MyFirst from './drkinhtiềnbạc/MyFirst.vue'
// import MyProtocol from './drkinhtiềnbạc/MyProtocol.vue'
// import MyPaso from './drkinhtiềnbạc/MyService.vue'
// import MyTechno from './drkinhtiềnbạc/MyTechno.vue'
// import MyContact from './drkinhtiềnbạc/MyAbout.vue'


// 越南petrolivay
// import MyFirst from './petrolivay/MyFirst.vue'
// import MyProtocol from './petrolivay/MyProtocol.vue'
// import MyPaso from './petrolivay/MyService.vue'
// import MyTechno from './petrolivay/MyTechno.vue'
// import MyContact from './petrolivay/MyAbout.vue'

// 越南lestardong
// import MyFirst from './lestardong/MyFirst.vue'
// import MyProtocol from './lestardong/MyProtocol.vue'
// import MyPaso from './lestardong/MyService.vue'
// import MyTechno from './lestardong/MyTechno.vue'
// import MyContact from './lestardong/MyAbout.vue'

// 越南sunvay
// import MyFirst from './sunvay/MyFirst.vue'
// import MyProtocol from './sunvay/MyProtocol.vue'
// import MyPaso from './sunvay/MyPaso.vue'
// import MyQuestions from './sunvay/MyQuestions.vue'
// import MyContact from './sunvay/MyContact.vue'

// 墨西哥paratidinero
// import MyFirst from './paratidinero/MyFirst.vue'
// import MyProtocol from './paratidinero/MyProtocol.vue'
// import MyPaso from './paratidinero/MyService.vue'
// import MyTechno from './paratidinero/MyTechno.vue'
// import MyContact from './paratidinero/MyAbout.vue'
// import MyQuestion from './paratidinero/MyQuestions.vue'

// 越南aitdong
// import MyFirst from './aitdong/MyFirst.vue'
// import MyProtocol from './aitdong/MyProtocol.vue'
// import MyPaso from './aitdong/MyService.vue'
// import MyUser from './aitdong/MyTechno.vue'
// import MyContact from './aitdong/MyAbout.vue'

//泰国microfin2
// import MyFirst from './microfin2/MyFirst.vue'
// import MyProtocol from './microfin2/MyProtocol.vue'
// import MyQuestions from './microfin2/MyQuestions.vue'
// import MyContact from './microfin2/MyAbout.vue'


// 泰国domcap2
// import MyFirst from './domcap2/MyFirst.vue'
// import MyProtocol from './domcap2/MyProtocol.vue'
// import MyPaso from './domcap2/MyService.vue'
// import MyQuestions from './domcap2/MyQuestions.vue'
// import MyContact from './domcap2/MyAbout.vue'

// 泰国ngernsaen2
// import MyFirst from './ngernsaen2/MyFirst.vue'
// import MyProtocol from './ngernsaen2/MyProtocol.vue'
// import MyPaso from './ngernsaen2/MyService.vue'
// import MyQuestions from './ngernsaen2/MyQuestions.vue'
// import MyContact from './ngernsaen2/MyAbout.vue'

// 越南firefigvay
// import MyFirst from './firefigvay/MyFirst.vue'
// import MyProtocol from './firefigvay/MyProtocol.vue'
// import MyPaso from './firefigvay/MyService.vue'
// import MyQuestions from './firefigvay/MyQuestions.vue'
// import MyContact from './firefigvay/MyAbout.vue'


// 越南sonthuyvay
// import MyFirst from './sonthuyvay/MyFirst.vue'
// import MyProtocol from './sonthuyvay/MyProtocol.vue'
// import MyPaso from './sonthuyvay/MyService.vue'
// import MyQuestions from './sonthuyvay/MyQuestions.vue'
// import MyContact from './sonthuyvay/MyAbout.vue'

// 越南truongtaionline
// import MyFirst from './truongtaionline/MyFirst.vue'
// import MyProtocol from './truongtaionline/MyProtocol.vue'
// import MyPaso from './truongtaionline/MyService.vue'
// import MyQuestions from './truongtaionline/MyQuestions.vue'
// import MyContact from './truongtaionline/MyAbout.vue'

// 越南dhloan
// import MyFirst from './dhloan/MyFirst.vue'
// import MyProtocol from './dhloan/MyProtocol.vue'
// import MyPaso from './dhloan/MyService.vue'
// import MyQuestions from './dhloan/MyQuestions.vue'
// import MyContact from './dhloan/MyAbout.vue'


// 墨西哥janodinero
// import MyFirst from './janodinero/MyFirst.vue'
// import MyProtocol from './janodinero/MyProtocol.vue'
// import MyPaso from './janodinero/MyService.vue'
// import MyQuestions from './janodinero/MyQuestions.vue'
// import MyContact from './janodinero/MyAbout.vue'

// 墨西哥kishonefectivo
// import MyFirst from './kishonefectivo/MyFirst.vue'
// import MyProtocol from './kishonefectivo/MyProtocol.vue'
// import MyCommunity from './kishonefectivo/MyCommunity.vue'
// import MyQuestions from './kishonefectivo/MyQuestions.vue'
// import MyContact from './kishonefectivo/MyAbout.vue'

// 越南longgsloan
// import MyFirst from './longgsloan/MyFirst.vue'
// import MyProtocol from './longgsloan/MyProtocol.vue'
// import MyCommunity from './longgsloan/MyCommunity.vue'
// import MyQuestions from './longgsloan/MyQuestions.vue'
// import MyContact from './longgsloan/MyAbout.vue'

//越南hanh
// import MyFirst from './hanh/MyFirst.vue'
// import MyProtocol from './hanh/MyProtocol.vue'
// import MyCommunity from './hanh/MyCommunity.vue'
// import MyQuestions from './hanh/MyQuestions.vue'
// import MyContact from './hanh/MyAbout.vue'


//泰国subkamolplus
// import MyFirst from './subkamolplus/MyFirst.vue'
// import MyProtocol from './subkamolplus/MyProtocol.vue'
// import MyCommunity from './subkamolplus/MyCommunity.vue'
// import MyQuestions from './subkamolplus/MyQuestions.vue'
// import MyContact from './subkamolplus/MyAbout.vue'

//泰国ktcnano
// import MyFirst from './ktcnano/MyFirst.vue'
// import MyProtocol from './ktcnano/MyProtocol.vue'
// import MyQuestions from './ktcnano/MyQuestions.vue'
// import MyContact from './ktcnano/MyAbout.vue'


//泰国nano
// import MyFirst from './nano/MyFirst.vue'
// import MyProtocol from './nano/MyProtocol.vue'
// import MyPaso from './nano/MyService.vue'
// import MyQuestions from './nano/MyQuestions.vue'
// import MyContact from './nano/MyAbout.vue'

//墨西哥lemonade
// import MyFirst from './lemonade/MyFirst.vue'
// import MyProtocol from './lemonade/MyProtocol.vue'
// import MyPaso from './lemonade/MyService.vue'
// import MyQuestions from './lemonade/MyQuestions.vue'
// import MyContact from './lemonade/MyAbout.vue'


//越南cbmalldong
// import MyFirst from './cbmalldong/MyFirst.vue'
// import MyProtocol from './cbmalldong/MyProtocol.vue'
// import MyPaso from './cbmalldong/MyService.vue'
// import MyQuestions from './cbmalldong/MyQuestions.vue'
// import MyAbout from './cbmalldong/MyAbout.vue'

//越南onsenmobile
// import MyFirst from './onsenmobile/MyFirst.vue'
// import MyProtocol from './onsenmobile/MyProtocol.vue'
// import MyPaso from './onsenmobile/MyService.vue'
// import MyQuestions from './onsenmobile/MyQuestions.vue'
// import MyAbout from './onsenmobile/MyAbout.vue'

//墨西哥lordanoupréstamos
// import MyFirst from './lordanoupréstamos/MyFirst.vue'
// import MyProtocol from './lordanoupréstamos/MyProtocol.vue'
// import MyPaso from './lordanoupréstamos/MyService.vue'
// import MyQuestions from './lordanoupréstamos/MyQuestions.vue'
// import MyAbout from './lordanoupréstamos/MyAbout.vue'

//泰国creditmonitor
// import MyFirst from './creditmonitor/MyFirst.vue'
// import MyProtocol from './creditmonitor/MyProtocol.vue'
// import MyPaso from './creditmonitor/MyService.vue'
// import MyQuestions from './creditmonitor/MyQuestions.vue'
// import MyAbout from './creditmonitor/MyAbout.vue'

//墨西哥mejorandopresta
// import MyFirst from './mejorandopresta/MyFirst.vue'
// import MyProtocol from './mejorandopresta/MyProtocol.vue'
// import MyPaso from './mejorandopresta/MyService.vue'
// import MyQuestions from './mejorandopresta/MyQuestions.vue'
// import MyAbout from './mejorandopresta/MyAbout.vue'

//越南koocheemoney
// import MyFirst from './koocheemoney/MyFirst.vue'
// import MyProtocol from './koocheemoney/MyProtocol.vue'
// import MyPaso from './koocheemoney/MyService.vue'
// import MyQuestions from './koocheemoney/MyQuestions.vue'
// import MyAbout from './koocheemoney/MyAbout.vue'

//墨西哥nitriodinero
// import MyFirst from './nitriodinero/MyFirst.vue'
// import MyProtocol from './nitriodinero/MyProtocol.vue'
// import MyPaso from './nitriodinero/MyService.vue'
// import MyQuestions from './nitriodinero/MyQuestions.vue'
// import MyAbout from './nitriodinero/MyAbout.vue'


//越南nitriodinero2
import MyFirst from './nitriodinero2/MyFirst.vue'
import MyProtocol from './nitriodinero2/MyProtocol.vue'
import MyPaso from './nitriodinero2/MyService.vue'
import MyQuestions from './nitriodinero2/MyQuestions.vue'
import MyAbout from './nitriodinero2/MyAbout.vue'

//越南zscredit
// import MyFirst from './zscredit/MyFirst.vue'
// import MyProtocol from './zscredit/MyProtocol.vue'
// import MyService from './zscredit/MyService.vue'
// import MyQuestions from './zscredit/MyQuestions.vue'
// import MyAbout from './zscredit/MyAbout.vue'

// 墨西哥joylendefectivo
// import MyFirst from './joylendefectivo/MyFirst.vue'
// import MyProtocol from './joylendefectivo/MyProtocol.vue'
// import MyPaso from './joylendefectivo/MyService.vue'
// import MyQuestions from './joylendefectivo/MyQuestions.vue'
// import MyContact from './joylendefectivo/MyAbout.vue'

//墨西哥activosmóvil
// import MyFirst from './activosmóvil/MyFirst.vue'
// import MyProtocol from './activosmóvil/MyProtocol.vue'
// import MyQuestions from './activosmóvil/MyQuestions.vue'
// import MyContact from './activosmóvil/MyAbout.vue'
// import MyAboutUs from './activosmóvil/MyAboutUs.vue'
// import MyDevelop from './activosmóvil/MyDevelop.vue'
// import MyMission from './activosmóvil/MyMission.vue'

// 越南giafinance
// import MyFirst from './giafinance/MyFirst.vue'
// import MyProtocol from './giafinance/MyProtocol.vue'
// import MyPaso from './giafinance/MyService.vue'
// import MyQuestions from './giafinance/MyQuestions.vue'
// import MyContact from './giafinance/MyAbout.vue'

Vue.use(VueRouter)
//越南namvaytien
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },
   
//   ]

//泰国chatchaimobile
  // const routes = [
  //   {
  //     path: '/',
  //     component: MyFirst, // 默认路由
  //   },
   
  //   {
  //     path: '/protocol',
  //     component: MyProtocol, // protocol 路由
  //   },
  // ]

//泰国jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/service',
//         component: MyService, // /list 路由
//       },
   
//   ]
//泰国第二个jaidee
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
//     {
//         path: '/about',
//         component: MyAbout, // /list 路由
//       },
   
//   ]
//越南mrVaymobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/contact',
//     component: MyContact, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
 
// ]

//越南kasikvay
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
//     {
//       path: '/clause',
//       component: MyClause, // 默认路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]

//越南tamogiefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestion, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/center',
//       component: MyCenter, // /list 路由
//     },
 
 
// ]

//墨西哥consedeefectivo
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
 
//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]


// 越南betacreditdinero
// const routes = [
//     {
//       path: '/',
//       component: MyFirst, // 默认路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
    
  
//     {
//       path: '/contant',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/protocol',
//       component: MyProtocol, // product 路由
//     },
  
   
//   ]
// 越南nathcopay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // /list 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

 
// ]


// 越南hcthlogplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/about',
//     component: MyAbout, // /list 路由
//   },
//   {
//     path: '/process',
//     component: MyProcess, // /list 路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

 
// ]

// 
//墨西哥hipotecariabilletera
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/process',
//     component: MyProcess, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/contrato',
//       component: MyContrato, // /list 路由
//     },
// ]


//越南sunmobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]

//越南cathaydonge
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
// ]


//越南newvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
// ]

//墨西哥eldefiardinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/support',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/news',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]


//越南thanhchungplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/support',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/news',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]


//越南Dealcorp Efectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]


//墨西哥kampalacash
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]


//泰国pheera
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]


//越南piwebplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南piwebplus2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥directodoefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国Jaideeonline
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]


//墨西哥braskemefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥dealcorpefectivo2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥ceibamóvil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },
    
    
// ]


//墨西哥estimulocrédito
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/help',
//       component: MyHelp, // /list 路由
//     },
//     {
//       path: '/process',
//       component: MyProcess, // /list 路由
//     },
//     {
//       path: '/condition',
//       component: MyCondition, // /list 路由
//     },
    
// ]
//墨西哥cincodospréstamos
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/tips',
//       component: MyTips, // /list 路由
//     },
    
    
// ]

//墨西哥concreecefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
    
    
// ]

//墨西哥daraptipago和wiicredi
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国microfinmobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/community',
//       component: MyCommunity, // /list 路由
//     },
    
    
// ]


//墨西哥ammóvil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]



//墨西哥ngernsaen
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国domcap
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥cherr2||operadoracredito
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
//     {
//       path: '/help',
//       component: MyHelp, // /list 路由
//     },
//     {
//       path: '/user',
//       component: MyProtocol2, // /list 路由
//     },
// ]


//墨西哥facicasapresta
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },

 
// ]

//墨西哥eliteloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南drkinhtiềnbạc
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/techno',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]


//越南petrolivay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/news',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南lestardong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/news',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南sunvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/paso',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/contact',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥paratidinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/news',
//     component: MyTechno, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/questions',
//       component: MyQuestion, // /list 路由
//     },
// ]


//越南aitdong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/user',
//     component: MyUser, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]


//泰国microfin2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
  
// ]


//泰国domcap2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国ngernsaen2
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南firefigvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/help',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南sonthuyvay
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南truongtaionline
// const routes = [
//   {
//     path: '/',
//     name: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/user',
//       component: MyContact, // /list 路由
//     },
// ]
//越南dhloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]


//墨西哥janodinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//墨西哥kishonefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南longgsloan
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//越南hanh
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]


//泰国subkamolplus
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/contact',
//       component: MyCommunity, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

//泰国ktcnano
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/story',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },

//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]


//墨西哥lemonade
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/about',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/user',
//       component: MyContact, // /list 路由
//     },
// ]

//越南cbmalldong
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/structure',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/introduce',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南onsenmobile
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//墨西哥lordanoupréstamos
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//泰国creditmonitor
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

// //墨西哥mejorandopresta
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//越南koocheemoney
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]

//墨西哥nitriodinero
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyAbout, // /list 路由
//     },
// ]


//越南nitriodinero2
const routes = [
  {
    path: '/',
    component: MyFirst, // 默认路由
  },
  {
    path: '/questions',
    component: MyQuestions, // 默认路由
  },
  {
    path: '/protocol',
    component: MyProtocol, // product 路由
  },
  {
      path: '/service',
      component: MyPaso, // /list 路由
    },
    {
      path: '/about',
      component: MyAbout, // /list 路由
    },
]

//越南zscredit
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/product',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyService, // /list 路由
//     },
//     {
//       path: '/news',
//       component: MyAbout, // /list 路由
//     },
// ]


//墨西哥joylendefectivo
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]


//墨西哥activosmóvil
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },

//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/questions',
//       component: MyQuestions, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
//     {
//       path: '/about_us',
//       component: MyAboutUs, // /list 路由
//     },
//     {
//       path: '/develop',
//       component: MyDevelop, // /list 路由
//     },
//     {
//       path: '/mission',
//       component:  MyMission, // /list 路由
//     }, 
// ]

//越南giafinance
// const routes = [
//   {
//     path: '/',
//     component: MyFirst, // 默认路由
//   },
//   {
//     path: '/questions',
//     component: MyQuestions, // 默认路由
//   },
//   {
//     path: '/protocol',
//     component: MyProtocol, // product 路由
//   },
//   {
//       path: '/service',
//       component: MyPaso, // /list 路由
//     },
//     {
//       path: '/about',
//       component: MyContact, // /list 路由
//     },
// ]

const router = new VueRouter({
  mode: 'hash', // 设置路由模式为 hash
  routes
})

export default router
